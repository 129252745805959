import React , { useState, useEffect } from 'react'
import { useTelegram } from "../../hooks/useTelegram"
import { useNavigate } from "react-router-dom";


export default function Instructions() {
    const {tg, user, telegram_id, first_name} = useTelegram()
    const navigate = useNavigate();

    useEffect(() => {
        var BackButton = tg.BackButton;
        BackButton.show();
        BackButton.onClick(function() {
            navigate(-1);
            BackButton.hide();

        });

    },[])
  return (
   <div className='instructions'>
<h3>📣  ИНСТРУКЦИИ</h3>
<br/>
<div>1️⃣ Регистрация кошелека 🎬 видео <a href='https://youtu.be/VpkOAZMfNgQ'>ЗДЕСЬ</a></div>

<div>2️⃣ Пополнение кошелька <a href='https://youtu.be/3AsywSTFZgA'>ПО ШАГАМ</a></div>

<div>3️⃣ Как активировать API ключ? <a href='https://youtu.be/r2zGKfWdM8s'>СМОТРИМ</a></div>

<div>4️⃣ Как добавить новые/наторгованные средства обратно в API? <a href='https://youtu.be/TYbKHA0Dx_s'>СМОТРЕТЬ</a></div>

<div>5️⃣ Регистрация Smart Club BOT ⭐️ <a href='https://www.youtube.com/watch?v=Qxu7iWMqyzg'>СМОТРЕТЬ</a></div>
   </div>
  )


   
}