import React, { useState, useEffect } from 'react'
import { useTelegram } from "../../hooks/useTelegram"
import { useNavigate } from "react-router-dom";
import { Input, Tooltip, Button, List, Avatar } from 'antd';
import language from '../../language/language.js'


export default function InfoPage({ img, link, titleLink, userData }) {
    const { tg, user, telegram_id, first_name } = useTelegram()
    const navigate = useNavigate();
    const lang = (userData?.data?.lang ? userData.data.lang:'ru' )

    useEffect(() => {
        var BackButton = tg.BackButton;
        BackButton.show();
        BackButton.onClick(function () {
            navigate(-1);
            BackButton.hide();

        });
        var MainButton = tg.MainButton;
        MainButton.text = language[lang].qery_now
        MainButton.color = "rgb(251, 144, 46)";
        MainButton.onClick(function () {
            if(link.startsWith('http')){
                window.open(link)
            } else {
                navigate(link);

            }
            MainButton.hide();

        })
        MainButton.show();
        

    }, [userData])
    return (
        <div className='infoPage'>
            <img
                style={{
                    width: '100%',
                    height: 'auto',
                    marginBottom: 10,
                }}
                src={img} alt={language[lang].qery_now}
            />

            <div className="btnBlock"
                style={{
                    marginBottom: 10,
                }}
            >
            </div>

        </div>
    )



}