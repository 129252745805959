import React, { useEffect } from 'react';
import { Form, Input, Button, Tabs, Select, Skeleton, Space, message } from 'antd';
import axios from 'axios'


function LoginPage({setIsAuthenticated}) {

  const onFinish = (values) => {
    try {
      axios.post(`https://smartclub.site/api/`, values ).then((response) => {
        updateAuth(response)
      }).catch(error => {
        console.log(error);
        
      });

    } catch (error) {
      console.log(error);
      
    }
  }


  const updateAuth = (newValue) => {
    sessionStorage.setItem("token", newValue); // Сохраняем новое значение
    sessionStorage.setItem("auth", true); // Сохраняем новое значение
    setIsAuthenticated(true); // Обновляем состояние
  };

  return (
    <div className="LoginPage">
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <div style={{ padding: 24, border: '1px solid #f0f0f0', borderRadius: 8 }}>
          <Form
            name="sign_in"
            onFinish={onFinish}
            layout="vertical"
          >
            <Form.Item
              label="Username"
              name="name"
              rules={[{ required: true, message: 'Please input your username!' }]}
            >
              <Input placeholder="Username" />
            </Form.Item>

            <Form.Item
              label="Password"
              name="pwd"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                Sign In
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}




export default LoginPage;
