import React , { useState, useEffect } from 'react'
import { useTelegram } from "../../hooks/useTelegram"
import { useNavigate } from "react-router-dom";
import { Viewer, Worker } from '@react-pdf-viewer/core';
// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// import '@react-pdf-viewer/core/lib/styles/index.css';
// import '@react-pdf-viewer/default-layout/lib/styles/index.css';

export default function Instructions() {
    const {tg, user, telegram_id, first_name} = useTelegram()
    const navigate = useNavigate();
    const pdfUrl = './SC presa (2).pdf'; // Укажите путь к вашему PDF файлу
    useEffect(() => {
        var BackButton = tg.BackButton;
        BackButton.show();
        BackButton.onClick(function() {
            navigate(-1);
            BackButton.hide();

        });

    },[])
  return (
   <>
    {/* <PdfViewer pdfUrl={pdfUrl} /> */}
   </>
  )


   
}


const PdfViewer = ({ pdfUrl }) => {
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    return (
        <div style={{ height: '750px' }}>
            <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}>
                <Viewer
                    fileUrl={pdfUrl}
                    plugins={[defaultLayoutPluginInstance]}
                />
            </Worker>
        </div>
    );
};