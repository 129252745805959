import React , { useState } from 'react'
import axios from 'axios'
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { Button, Layout, Menu, theme, Modal, Form, Input} from 'antd';
const { Header, Sider, Content } = Layout;


export default function Admin({ children }) {
  const [password, setPassword] = useState('');
  const [collapsed, setCollapsed] = useState(false);

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const onSubmit = async (obj) => {
    try {
      const response = axios.post(`https://smartclub.site/api/admin`, obj)
      console.log(response.data)
    } catch (error) {
      console.log(error)
    }
    }
  const onchangePassword = (e) => {
    setPassword((prev) => ({ ...prev, password: e.target.value }))
  }
  


  return (
    <Layout>
    <Sider trigger={null} collapsible collapsed={collapsed}>
      <div className="demo-logo-vertical" />
      <Menu
        theme="dark"
        mode="inline"
        items={[
          {
            key: '1',
            icon: <UserOutlined />,
            label: <a href='/admin/'>User</a>,
          },
          {
            key: '2',
            icon: <UserOutlined />,
            label: <a href='/admin/request/'>Request</a>,
          },
          {
            key: '3',
            icon: <UserOutlined />,
            label: <a href='/admin/finances/'>Withdrawal</a>,
          },
          {
            key: '4',
            icon: <UserOutlined />,
            label: <a href='/admin/history/'>History</a>,
          }
          ,
          {
            key: '5',
            icon: <UserOutlined />,
            label: <a href='/admin/discord/'>Discord</a>,
          },
          {
            key: '6',
            icon: <UserOutlined />,
            label: <a href='/admin/alerts/'>Alerts</a>,
          }
        ]}
      />
    </Sider>
    <Layout>
      <Header
        style={{
          padding: 0,
          background: colorBgContainer,
        }}
      >
        <Button
          type="text"
          icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={() => setCollapsed(!collapsed)}
          style={{
            fontSize: '16px',
            width: 64,
            height: 64,
          }}
        />
        
      </Header>
      <Content
        style={{
          margin: '24px 16px',
          padding: 24,
          minHeight: 280,
          background: colorBgContainer,
          borderRadius: borderRadiusLG,
        }}
      >
        {children}
      </Content>
    </Layout>
  </Layout>
  )
  return(
    <Modal
      title="Авторизуйтесь"
      open={true}
      footer=""
      >
    <Form
        name="basic"
        labelCol={{
          span: 5,
        }}
        wrapperCol={{
          span: 19,
        }}
        style={{
          maxWidth: 600,
        }}
        initialValues={{
          remember: true,
        }}
        autoComplete="off"
      >
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
          
        >
          <Input.Password value={password} onChange={onchangePassword}/>
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 10,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit" onClick={() => onSubmit(password)}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )


   
}