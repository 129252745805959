import { useState, useEffect } from "react"
import Club from "../Club/Club"
import Trade from "../Trade/Trade"
import { useNavigate } from "react-router-dom";
import TradeRegister from "../Trade/TradeRegister"



export default function Main({userData}) {
    const navigate = useNavigate()

    const [tabe, setTab] = useState('trade')

   
    const onTabTrade = () => {
        setTab('trade')
    }
    const onTabClub = () => {
        setTab('club')
    }
    // if (userData?.message == 'Пользователь не найден' || userData?.message == 'Доступ запрещен') {
    //     return navigate("/form")
    // }

   
    if(tabe==='trade'){
        
        if (userData?.data?.trade_options){
            return (
                <div className="main">
                     <Trade tabe={tabe} onTabClub={onTabClub} onTabTrade={onTabTrade} userData={userData}/>
                </div>
             )
        }else{
            return(
                <div className="main">
                    <TradeRegister userData={userData}/>
                </div>
                )
        }

    }else if(tabe==='club'){
        return (
            <div className="main">
                 <Club tabe={tabe} onTabClub={onTabClub} onTabTrade={onTabTrade} userData={userData}/>
            </div>
         )
    }

    
}