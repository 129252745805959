import React, {useState, useEffect} from 'react'
import { Layout, List, Avatar, Input } from 'antd'
import axios from 'axios'
import { SearchOutlined } from '@ant-design/icons';

export default function History() { 
  const [ reqList, setReqList ] = useState([])
  const [ search, setSearch ] = useState('')

  useEffect(() => {
    async function fetchData() {
        axios.get(`https://smartclub.site/api/history/all`).then((response) => {
            setReqList(response.data.result.reverse());
          });
       
    }
    fetchData ()
  },[])

  const onchangeSearch = (e) => {
    setSearch(e.target.value)
  }

  const filteredUsers = reqList.filter((user) =>
    user?.first_name?.toLowerCase()?.includes(search?.toLowerCase()) ||
    user?.username?.toLowerCase()?.includes(search?.toLowerCase()) || 
    user?.email?.toLowerCase()?.includes(search?.toLowerCase()) || 
    user?.hash_login?.toLowerCase()?.includes(search?.toLowerCase()) || 
    user?.hash_transaction?.toLowerCase()?.includes(search?.toLowerCase()) || 
    user?.wallet?.toLowerCase()?.includes(search?.toLowerCase()) ||
    user?.id_telegram?.toString()?.includes(search)


  );

  return (
    <>
     <Input
      placeholder="Поиск"
      onChange={onchangeSearch}
      suffix={<SearchOutlined />}
      style={{
        width: 200,
      }}
    />
        <List
          className="demo-loadmore-list"
          itemLayout="horizontal"
          dataSource={filteredUsers}
          pagination={{
            onChange: (page) => {
              console.log(page);
            },
            pageSize: 20,
          }}
          renderItem={(item) => (
            <List.Item>
                <List.Item.Meta
                  title={<a href={"/admin/user/" + item.id_telegram}>{item.first_name}</a>}
                  description={<><div><span>Email:  </span>{item.email}</div><div><span>Action:  </span>{item.action}</div><div><span>Date:  </span>{item.date}</div><div><span>Summ:  </span>{item.summ}</div></>}
                />
                {/* <div>content</div> */}
            </List.Item>
          )}
        />
    </>

      )
} 

