import { useState, useEffect } from "react"
import { Input, Tooltip } from 'antd';
import axios from 'axios'
import { useTelegram } from "../hooks/useTelegram"
import language from '../language/language.js'
import { useNavigate } from "react-router-dom";


export default function FormApiPage({userData, type}) {   
    const nav = useNavigate() 
    const lang = (userData?.data?.lang ? userData.data.lang : 'ru')
    const {tg, user, telegram_id, first_name} = useTelegram()
    const navigate = useNavigate();

    useEffect(() => {
        var BackButton = tg.BackButton;
        BackButton.show();
        BackButton.onClick(function() {
            navigate(-1);
            BackButton.hide();

        });

    },[])
    const [ data, setData ] = useState({
        type: type,
        id_telegram: telegram_id,
        api: '',
        hash: '',
    })
    const [ register, setRegister ] = useState(false)


    const onAddUserTrade = async (obj) => {
        await axios.patch('https://smartclub.site/api/trade', obj).then(function (response) {
            if(response.status){
                setRegister(true)
            }
          });
        }

    const onchangeDataApi = (e) => {
        setData((prev) => ({ ...prev, api: e.target.value }))
    }

    const onchangeDataHash = (e) => {
        setData((prev) => ({ ...prev, hash: e.target.value }))
    }

    const onchangeDataSumm = (e) => {
        setData((prev) => ({ ...prev, summ: e.target.value }))
    }
    useEffect(() => {
        if(register){
            nav('/')
        }

    },[register])
        return (
            <div className="MailList">

                 <div className="form">
                     <div className="header__logo">
                     {
                        language[lang].qery_title
                    }
                     </div>
                     <br />
                     <br />
                     <div>
                         <Input prefix="API" value={data.api} onChange={onchangeDataApi}/>
                     </div>
                     <br />
                     <br />
                     <div>
                         <Input prefix="Hash" value={data.hash} onChange={onchangeDataHash}/>
                     </div>
                     <br />
                     <br />
                     <div className="btnBlock" >
                         <div className="btn" style={{padding: 10}} onClick={() => onAddUserTrade(data)}>{language[lang].submit}</div>
                     </div>
                 </div>
                 
     
            </div>
         )
}