import React from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react"
import axios from 'axios'
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';

import {
  Button,
  Cascader,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Switch,
  TreeSelect,
  Checkbox,
  Modal
} from 'antd';



export default function AdminUser() { 
  const { id_telegram } = useParams()
  const [ userData, setUserData ] = useState([])
  const [ items, setItems ] = useState([])

  const navigate = useNavigate()


  const [ modal, setModal ] = useState({
    title: '',
    open: false,
    type: '',
    value: 0,
    user: 0
  })
  const onChange = (key) => {
    console.log(key);
};
useEffect(() => {
  async function fetchData() {
    
      axios.get(`https://smartclub.site/api/user/${id_telegram}`).then((response) => {
          setUserData(response.data.data);
          setItems(
            [
                {
                    key: '1',
                    label: 'Все',
                    children: (response.data.referralsTrade?.level1 ? response.data.referralsTrade.level1.map(referral => ({ ...referral, label: '1' })).concat(response.data.referralsTrade.level2.map(referral => ({ ...referral, label: '2' })), response.data.referralsTrade.level3.map(referral => ({ ...referral, label: '3' }))) : []),
                },
                {
                    key: '2',
                    label: '1 ур',
                    children: (response.data.referralsTrade?.level1 ? response.data.referralsTrade?.level1.map(referral => ({ ...referral, label: '1' })) : []),
                },
                {
                    key: '3',
                    label: '2 ур',
                    children: (response.data.referralsTrade?.level2 ? response.data.referralsTrade?.level2.map(referral => ({ ...referral, label: '2' })) : []),
                },
                {
                    key: '4',
                    label: '3 ур',
                    children: (response.data.referralsTrade?.level3 ? response.data.referralsTrade?.level3.map(referral => ({ ...referral, label: '3' })) : []),
                },
                {
                    key: '5',
                    label: '4 ур',
                    children: (response.data.referralsTrade?.level4 ? response.data.referralsTrade?.level4.map(referral => ({ ...referral, label: '4' })) : []),
                },
                {
                    key: '6',
                    label: '5 ур',
                    children: (response.data.referralsTrade?.level5 ? response.data.referralsTrade?.level5.map(referral => ({ ...referral, label: '5' })) : []),
                },
            ]  )
        });

  }
  fetchData ()
},[])


  const onClickModal = (type) => {
    if(type=='club') {
      setModal({
        title: 'Клубные',
        open: true,
        type: type,
        value: 0,
        user: id_telegram
      })
    } else if (type=='trade') {
      setModal({
        title: 'Торговые',
        open: true,
        type: type,
        value: 0,
        user: id_telegram
      })
    } else if (type=='revard') {
      setModal({
        title: 'Ревард',
        open: true,
        type: type,
        value: 0,
        user: id_telegram
      })
    } 
  }

  const closeModal = (e) => {
    setModal({
      open: false,
      type: '',
      value: 0,
      user: id_telegram
    })
  }

  const onSaveModal = async (obj) => {
    console.log('obj',obj);
    
    const normalizedInput = obj.value.replace(',', '.');
    // Преобразуем строку в число
    const number = parseFloat(normalizedInput);

    // Проверяем, является ли введенное значение числом
    if (isNaN(number)) {
      obj.value = 0
    } else {
      obj.value = number
    }

    await axios.post(`https://smartclub.site/api/admin/balance/`, obj)
    setModal({
      open: false,
      type: '',
      value: 0,
      user: id_telegram
    })
    window.location.reload()
  }

  const onchangeModal = (e) => {
    setModal((prev) => ({ ...prev, value: e.target.value }));
  }
  

  const onchangeEmail = (e) => {
    setUserData((prev) => ({ ...prev, email: e.target.value }))
  }

  const onchangeId_telegram = (e) => {
    setUserData((prev) => ({ ...prev, id_telegram: e.target.value }))
  }

  const onchangeUsername = (e) => {
    setUserData((prev) => ({ ...prev, username: e.target.value }))
  }

  const onchangeFirst_name = (e) => {
    setUserData((prev) => ({ ...prev, first_name: e.target.value }))
  }

  const onchangeClub_balance = (e) => {
    setUserData((prev) => ({ ...prev, club_balance: e.target.value }))
  }

  const onchangeClub_options = (e) => {    
    setUserData((prev) => ({ ...prev, club_options: e }))
  }

  const onchangeTrade_balance = (e) => {
    setUserData((prev) => ({ ...prev, trade_balance: e.target.value }))
  }

  const onchangeTrade_options = (e) => {
    setUserData((prev) => ({ ...prev, trade_options: e }))
  }

  const onchangeLeader_options = (e) => {
    setUserData((prev) => ({ ...prev, leader: e }))
  }

  const onchangeHash_login = (e) => {
    setUserData((prev) => ({ ...prev, hash_login: e.target.value }))
  }

  const onchangeHash_transaction = (e) => {
    setUserData((prev) => ({ ...prev, hash_transaction: e.target.value }))
  }

  const onchangeReward = (e) => {
    setUserData((prev) => ({ ...prev, reward: e.target.value }))
  }

  const onchangeReward_full = (e) => {
    setUserData((prev) => ({ ...prev, reward_full: e.target.value }))
  }

  const onchangeReward_bust = (e) => {
    setUserData((prev) => ({ ...prev, reward_bust: e.target.value }))
  }

  const onchangeRecommended = (e) => {
    setUserData((prev) => ({ ...prev, recommended: e.target.value }))
  }

  const onSaveUser = async (obj) => {

    await axios.post(`https://smartclub.site/api/admin/user/`, obj)
    window.location.reload()
    }
  const onDeleteUser = async (obj) => {
    // console.log(obj);
    
    await axios.post(`https://smartclub.site/api/admin/user/delete/`, obj)
      navigate('/admin/')
    }

    return (
      <>
      <div className='adminFormUser'>
      <Form
      labelCol={{
        span: 5,
      }}
      wrapperCol={{
        span: 10,
      }}
      layout="horizontal"
      style={{
        maxWidth: 600,
      }}
    >
      <Form.Item label="email">
        <Input value={userData?.email} onChange={onchangeEmail}/>
      </Form.Item>

      <Form.Item label="id_telegram">
        <Input value={userData?.id_telegram} onChange={onchangeId_telegram}/>
      </Form.Item>

      <Form.Item label="username">
        <Input value={userData?.username} onChange={onchangeUsername}/>
      </Form.Item>

      <Form.Item label="first_name">
        <Input value={userData?.first_name} onChange={onchangeFirst_name}/>
      </Form.Item>

      <Form.Item label="club_balance">
        <Input value={userData?.club_balance} onChange={onchangeClub_balance}/>
        <Switch value={userData?.club_options} onChange={onchangeClub_options}/>
        <Button type="primary" onClick={() => onClickModal('club')}>Начислить клубный</Button>
      </Form.Item>

      <Form.Item label="trade_balance">
        <Input value={userData?.trade_balance} onChange={onchangeTrade_balance}/>
        {/* <Checkbox сhecked={userData?.trade_options ? true : false} onChange={onchangeTrade_options}></Checkbox> */}
        <Switch  value={userData?.trade_options ? true : false} onChange={onchangeTrade_options}/>
        <Button type="primary" onClick={() => onClickModal('trade')}>Начислить торговы</Button>
      </Form.Item>

      <Form.Item label="hash_login">
        <Input value={userData?.hash_login} onChange={onchangeHash_login}/>
      </Form.Item>

      <Form.Item label="hash_transaction">
        <Input value={userData?.hash_transaction} onChange={onchangeHash_transaction}/>
      </Form.Item>
      
      <Form.Item label="reward">
        <Input value={userData?.reward} onChange={onchangeReward}/>
        <Button type="primary" onClick={() => onClickModal('revard')}>Начислить ревард</Button>
      </Form.Item>
      
      <Form.Item label="reward_full">
        <Input value={userData?.reward_full} onChange={onchangeReward_full}/>
      </Form.Item>

      <Form.Item label="reward_bust">
        <Input value={userData?.reward_bust} onChange={onchangeReward_bust}/>
      </Form.Item>

      <Form.Item label="recommended">
        <Input value={userData?.recommended} onChange={onchangeRecommended}/>
        <a href={'/admin/user/'+userData?.recommended}>{userData?.recommended}</a>
      </Form.Item>

      <Form.Item label="leader">
        <Switch  value={userData?.leader ? true : false} onChange={onchangeLeader_options}/>
      </Form.Item>

      <Button type="primary" onClick={() => onSaveUser(userData)}>Сохранить</Button>
      <Button type="primary" onClick={() => onDeleteUser(userData)}>Удалить</Button>

    </Form>
    <div className='adminTabs'>

    <div className="referal__list">
                <div className="referal__list--body">
                    <Tabs 
                        className="referal__list--body--ul"
                        defaultActiveKey="1" 
                        onChange={onChange} 
                        indicator={{
                            size: 0,
                          }}
                        items={items.map((item) => ({
                                label: item.label,
                                key: item.key,
                                children:  item.children.length > 0 ? item.children.map((child) => (
                                            <div className="referal__list--body--li" key={child.id}>
                                                <div className="referal__list--body-li-left">
                                                    <h5>{child.first_name}</h5>
                                                    <a href={`https://t.me/${child.username}`}><span>@{child.username}</span></a>
                                                </div>
                                                <div className="referal__list--body-li-right">
                                                    <h5>
                                                        <svg width="11.000000" height="11.000000" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <defs/>
                                                            <path id="Vector" d="M-0.5 5C-0.5 1.96 1.96 -0.5 5 -0.5C8.03 -0.5 10.5 1.96 10.5 5C10.5 6.55 9.85 7.96 8.8 8.96C7.81 9.91 6.47 10.5 5 10.5C3.52 10.5 2.18 9.91 1.19 8.96C0.14 7.96 -0.5 6.55 -0.5 5ZM8.11 8.56C7.89 7.9 7.26 7.43 6.53 7.43L3.46 7.43C2.73 7.43 2.1 7.9 1.88 8.56C2.71 9.29 3.8 9.73 5 9.73C6.19 9.73 7.28 9.29 8.11 8.56ZM5 1.03C3.65 1.03 2.56 2.12 2.56 3.46C2.56 4.8 3.65 5.89 5 5.89C6.34 5.89 7.43 4.8 7.43 3.46C7.43 2.12 6.34 1.03 5 1.03Z" fill="#50C787" fill-opacity="1.000000" fill-rule="evenodd"/>
                                                        </svg>
                                                        {child.label}
                                                    </h5>
                                                    <span>{child.email}</span>
                                                </div>
                                            </div>
                                    )) : (
                                        <div className="referal__list--body--empty-block">
                                            Рефералов данного уровня нет
                                        </div>
                                    )
                            }))}
                    />
                </div>
            </div>
    </div>

    </div>
    <Modal
        title={modal.title}
        centered
        open={modal.open}
        onOk={() => onSaveModal(modal)}
        onCancel={() => closeModal()}
        width={600}
      >
        <Input value={modal.value} onChange={onchangeModal}/>

    </Modal>
    </>
    )
}