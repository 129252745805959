import React, {useState, useEffect} from 'react'
import { Layout, List, Avatar, Input, Space  } from 'antd'
import axios from 'axios'
import { SearchOutlined } from '@ant-design/icons';
const { Search } = Input;

export default function ContentBlock() { 
  const [ users, setUsers ] = useState([])
  const [ search, setSearch ] = useState('')

  useEffect(() => {
    async function fetchData() {
        axios.get(`https://smartclub.site/api/user/all`).then((response) => {
            setUsers(response.data.users);
          });
    }
    fetchData ()
  },[])

  const onchangeSearch = (e) => {
    setSearch(e.target.value)
  }

  const filteredUsers = users.filter((user) =>
    user?.first_name?.toLowerCase()?.includes(search?.toLowerCase()) ||
    user?.username?.toLowerCase()?.includes(search?.toLowerCase()) || 
    user?.email?.toLowerCase()?.includes(search?.toLowerCase()) || 
    user?.hash_login?.toLowerCase()?.includes(search?.toLowerCase()) || 
    user?.hash_transaction?.toLowerCase()?.includes(search?.toLowerCase()) || 
    user?.wallet?.toLowerCase()?.includes(search?.toLowerCase()) ||
    user?.id_telegram?.toString()?.includes(search)


  );

  return (
    <>
    
    <Input
      placeholder="Поиск"
      onChange={onchangeSearch}
      suffix={<SearchOutlined />}
      style={{
        width: 200,
      }}
    />
  <List
      itemLayout="horizontal"
      dataSource={filteredUsers}
      pagination={{
        onChange: (page) => {
          console.log(page);
        },
        pageSize: 20,
      }}
      renderItem={(item, index) => (
        <List.Item>
          <List.Item.Meta
            avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${index}`} />}
            title={<a href={"/admin/user/"+item.id_telegram}>{item.first_name}</a>}
            description={item.email}
          />
        </List.Item>
      )}
    />
    </>
    )
}