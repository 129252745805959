import React, { useState, useEffect } from 'react'
import { useTelegram } from "../../hooks/useTelegram"
import { useNavigate } from "react-router-dom";
import { Input, Tooltip, Button, List, Avatar, Result } from 'antd';
import axios from 'axios'
import language from '../../language/language.js'


export default function FormInfoPage({userData}) {
    const [result, setResult] = useState(false)
    const lang = (userData?.data?.lang ? userData.data.lang:'ru' )

    const { tg, user, telegram_id, first_name } = useTelegram()
    const [data, setData] = useState({
        id_telegram: telegram_id,
        hash: '',
        discord: '',
        summ: 0,
    })
    const navigate = useNavigate();


    useEffect(() => {
        if(telegram_id){
            async function fetchData() {
                axios.get(`https://smartclub.site/api/discord/${telegram_id}`).then((response) => {
                    if(response.status){
                        setResult(response.data.result)
                    }
                  });
            }
            fetchData ()
        }
    },[])

    const onAddUserTrade = async (obj) => {
        await axios.post('https://smartclub.site/api/discord', obj).then(function (response) {
            if (response.status) {
                setResult(true)
                // navigate(-2);
            }
        });
    }

    const onchangeDataApi = (e) => {
        setData((prev) => ({ ...prev, hash: e.target.value }))
    }

    const onchangeDataDiscord = (e) => {
        setData((prev) => ({ ...prev, discord: e.target.value }))
    }

    const onchangeDataSumm = (e) => {
        setData((prev) => ({ ...prev, summ: e.target.value }))
    }

    useEffect(() => {
        var BackButton = tg.BackButton;
        BackButton.show();
        BackButton.onClick(function () {
            navigate(-1);
            BackButton.hide();

        });

    }, [])




    if (result) {
        return (
            <Result
                id = 'result'
                className='result'
                status="success"
                title={language[lang].qery_accepted}
                subTitle={language[lang].qery_processed}
            />
        )
    } else {
        return (
            <div
                style={{
                    padding: '0 10px',
                }}
            >
                <div className="header__logo">
                    <h3>{language[lang].qery_title}</h3>
                </div>
                <br />
                <br />
                <div>
                    <Input prefix={language[lang].sum_api} value={data.summ} onChange={onchangeDataSumm} />
                </div>
                <br />
                <br />
                <div>
                    <Input prefix={language[lang].key_api} value={data.hash} onChange={onchangeDataApi} />
                </div>
                <br />
                <br />
                <div>
                    <Input prefix="Discord Login" value={data.discord} onChange={onchangeDataDiscord} />
                </div>
                <br />
                <br />
                <div className="btnBlock">
                    <Button className="btn" onClick={() => onAddUserTrade(data)}>{language[lang].submit}</Button >
                </div>
                <br />
                <br />
            </div>
        )

    }



}