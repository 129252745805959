import React, {useState, useEffect} from 'react'
import { Layout, List, Avatar } from 'antd'
import axios from 'axios'

export default function DiscordList() { 
  const [ reqList, setReqList ] = useState([])
  useEffect(() => {
    async function fetchData() {
        axios.get(`https://smartclub.site/api/discord/all`).then((response) => {
            setReqList(response.data.result);
          });
    //     setReqList([
    //         {"id":1291,"id_telegram":5352570091,"api":"62f5d610-1931-4e82-a693-2077f0874cf6","hash":"418f1b53f37dfe123c924e50c7846a2568fbcef0c7c0590d7f698b883ae16dfe","summ":500,"success":0,"email":"samsung816541@gmail.com","username":null,"first_name":"Валентина","trade_balance":0,"club_balance":0,"hash_login":null,"hash_transaction":null,"recommended":1816023912,"reward":0,"wallet":null,"reward_full":0,"club_options":1,"trade_options":0},{"id":1681,"id_telegram":882926835,"api":"bbf8e980-640b-47b1-b049-7dd93fc35774","hash":"d319dafaea2cf7976c955b7e6d39a697bd80b3e85c2cd54715c532101381c791","summ":1003,"success":0,"email":"amtsomik@gmail.com","username":"amtsomik","first_name":"Оlexander","trade_balance":0,"club_balance":0,"hash_login":null,"hash_transaction":null,"recommended":469234178,"reward":0,"wallet":null,"reward_full":0,"club_options":1,"trade_options":0}]);
    }
    fetchData ()
  },[])

  const Confirm = async(id_telegram, summ) => {
    const normalizedInput = summ.toString().replace(',', '.');
    // Преобразуем строку в число
    const number = parseFloat(normalizedInput);

    // Проверяем, является ли введенное значение числом
    if (isNaN(number)) {
      summ = 0
    } else {
      summ = number
    }
    await axios.post('https://smartclub.site/api/discord/action', {
      id_telegram: id_telegram,
      summ: summ,
      action: 'confirm'
    })
    .then(function (response) {
      if (response.data.stat) {
        setReqList(currentState => currentState.filter(item => item.id_telegram !== id_telegram));        
      }
    })
  }

  const Reject = async (id_telegram, summ) => {
    await axios.post('https://smartclub.site/api/discord/action', {
      id_telegram: id_telegram,
      summ: summ,
      action: 'delete'
    })
    .then(function (response) {
      if (response.data.stat) {
        setReqList(currentState => currentState.filter(item => item.id_telegram !== id_telegram));        
      }
    })
  }

  return (
    <List
          className="demo-loadmore-list"
          itemLayout="horizontal"
          dataSource={reqList}
          renderItem={(item) => (
            <List.Item
              actions={[<a key="list-loadmore-more" onClick={() => Reject(item.id_telegram, item.summ)}>Подтвердить</a>]}
            >
                <List.Item.Meta
                  avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${item.index}`} />}
                  title={<a href={"user/" + item.id_telegram}>{item.username}</a>}
                  description={<><div><span>Discord:  </span>{item.discord}</div><div><span>Hash:  </span>{item.hash}</div><div><span>Сума:  </span>{item.summ}</div></>}
                />
                {/* <div>content</div> */}
            </List.Item>
          )}
        />)
}