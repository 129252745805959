import { useState, useEffect } from "react"
import { Input, Tooltip } from 'antd';
import axios from 'axios'
import { useTelegram } from "../../hooks/useTelegram"
import { useNavigate } from "react-router-dom";
import language from '../../language/language.js'


export default function Trade({userData, type}) {    
    const lang = (userData?.data?.lang ? userData.data.lang : 'ru')
    const {tg, user, telegram_id, first_name} = useTelegram()
    const navigate = useNavigate();
    const [ dataUser, setDataUser ] = useState({})

    useEffect(() => {
        var BackButton = tg.BackButton;
        BackButton.show();
        BackButton.onClick(function() {
            navigate(-1);
            BackButton.hide();

        });

    },[])
    const [ data, setData ] = useState({
        type: type,
        id_telegram: telegram_id,
        api: '',
        hash: '',
        summ: 0,
    })
    const [ register, setRegister ] = useState(false)

    useEffect(() => {
        if(telegram_id){
            async function fetchData() {
                axios.get(`https://smartclub.site/api/trade/${telegram_id}`).then((response) => {
                    if(response.status){
                        setRegister(response.data.result)
                        setDataUser(response.data.result_data)
                    }
                  });
            }
            fetchData ()
        }
    },[])

    const onAddUserTrade = async (obj) => {
        await axios.post('https://smartclub.site/api/trade', obj).then(function (response) {
            if(response.status){
                setRegister(true)
            }
          });
        }

    const onchangeDataApi = (e) => {
        setData((prev) => ({ ...prev, api: e.target.value }))
    }

    const onchangeDataHash = (e) => {
        setData((prev) => ({ ...prev, hash: e.target.value }))
    }

    const onchangeDataSumm = (e) => {
        setData((prev) => ({ ...prev, summ: e.target.value }))
    }
    // if(type==='add'){
    //     return(
    //         <div className="MailList">
    //         <div className="form">
    //             <div className="header__logo">
    //                Заполните заявку
    //             </div>
    //             <br />
    //             <br />
    //             <div>
    //                 <Input prefix="Hash" value={data.hash} onChange={onchangeDataHash}/>
    //             </div>
    //             <br />
    //             <br />
    //             <div>
    //                 <Input prefix="Сумма" value={data.summ} onChange={onchangeDataSumm}/>
    //             </div>
    //             <br />
    //             <br />
    //             <div className="btnBlock">
    //                 <div className="btn" onClick={() => onAddUserTrade(data)}>Отправить</div>
    //             </div>
    //         </div>
            

    //    </div>
    //     )
    // }
    if(register){
        return(
            <>
            <div className="registerTextBlock">
                <h3>
                    {
                        language[lang].qery_processed
                    }
                </h3>
            </div>
            </>
        )
    }else{
        return (
            <div className="MailList">

                 <div className="form">
                     <div className="header__logo">
                     {
                        language[lang].qery_title
                    }
                     </div>
                     <br />
                     <br />
                     <div>
                         <Input prefix="API" value={data.api} onChange={onchangeDataApi}/>
                     </div>
                     <br />
                     <br />
                     <div>
                         <Input prefix="Hash" value={data.hash} onChange={onchangeDataHash}/>
                     </div>
                     <br />
                     <br />
                     <div>
                         <Input prefix={language[lang].sum} value={data.summ} onChange={onchangeDataSumm}/>
                     </div>
                     <br />
                     <br />
                     <div className="btnBlock">
                         <div className="btn" onClick={() => onAddUserTrade(data)}>{language[lang].submit}</div>
                     </div>
                 </div>
                 
     
            </div>
         )
    }
}