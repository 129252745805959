import { useEffect, useState } from "react"
import { useTelegram } from "../../hooks/useTelegram"

import axios from 'axios'
import './Form.css'
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { InfoCircleOutlined, UserOutlined } from '@ant-design/icons';
import { Input, Tooltip, Button, Checkbox, Drawer } from 'antd';
import language from '../../language/language.js'
import img from './cs.jpg'
import imgen from './scen.jpg'
export default function Register() {
    const { tg, user, telegram_id, first_name } = useTelegram()
    const [checked1, setChecked1] = useState(false);
    const [checked2, setChecked2] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    // Получаем значение параметра языка
    const lang = queryParams.get('lang').toLocaleLowerCase(); // предположим, параметр называется 'lang'
    const showDrawer1 = () => {
        setOpen1(true);
    };
    const showDrawer2 = () => {
        setOpen2(true);
    };
    const onClose1 = () => {
        setOpen1(false);
    };
    const onClose2 = () => {
        setOpen2(false);
    };
    const { recommended } = useParams()
    const [rgUser, setRgUser] = useState({
        recommended: recommended,
        username: user?.username,
        first_name: first_name,
        id_telegram: telegram_id,
        clab: false,
        lang: lang
    })
    const [userData, setUserData] = useState([])
    const [showWallet, setShowWallet] = useState(userData?.data?.club_balance ? false : true)

    const navigate = useNavigate()

    useEffect(() => {
        if (telegram_id) {
            async function fetchData() {
                axios.get(`https://smartclub.site/api/user/${telegram_id}`).then((response) => {
                    setUserData(response.data);
                });
            }
            fetchData()
        }
    },)

    const onAddUser = async (obj) => {
        await axios.post('https://smartclub.site/api/register', obj)
        setShowWallet(false)
    }

    const onchangeEmail = (e) => {
        setRgUser((prev) => ({ ...prev, email: e.target.value }))
    }

    const onchangeWallet = (e) => {
        setRgUser((prev) => ({ ...prev, wallet: e.target.value }))
    }

    const onChange1 = (e) => {
        setChecked1(e.target.checked)
    };

    const onChange2 = (e) => {
        setChecked2(e.target.checked)
    };

    if (userData?.message == 'Доступ запрещен') {
        return (
            <div className="registerTextBlock">
                <span>{language[lang].register_pay_text}</span>
                <br />
                <br />
                <span>TF3FtZgyDza1cf4fqhczhqkqWUWgxqGwA4</span>
            </div>
        )
    }

    if (userData?.data?.club_options) {
        return navigate("/")
    } else {
        return (
            (showWallet ?
                <div className="form">
                    <h3>{language[lang].register_form_title}</h3>
                    <br />                    <img src={language[lang].referal_img} alt="" />

                    <br />
                    <Input
                        placeholder="Email"
                        prefix={
                            <UserOutlined
                                style={{
                                    color: 'rgba(0,0,0,.25)',
                                }}
                            />
                        }
                        value={rgUser.email}
                        onChange={onchangeEmail}
                    />
                    <br />
                    <br />
                    <Input suffix="Trc-20" value={rgUser.wallet} onChange={onchangeWallet} />
                    <br />
                    <br />
                    <Checkbox onChange={onChange1} checked={checked1}><a href="#" onClick={showDrawer1}>{language[lang].rules_title}</a></Checkbox>
                    <br />
                    <br />
                    <Checkbox onChange={onChange2} checked={checked2}><a href="#" onClick={showDrawer2}>{language[lang].user_greement}</a></Checkbox>
                    <br />
                    <br />
                    <div className="btnBlock">
                        <Button className="btn" onClick={() => onAddUser(rgUser)} disabled={!checked1 || !checked2}>{language[lang].submit}</Button>
                        {/* <div className="btn" onClick={() => onAddUser(rgUser)}>Отправить</div> */}
                    </div>


                    <Drawer
                        title={language[lang].rules_title} onClose={onClose1} open={open1} placement='bottom' key='bottom' className='drawerCustom'
                    >
                        <img
                            style={{
                                width: '100%',
                                height: 'auto',
                                marginBottom: 10,
                            }}
                            src={lang == 'ru' ? img : imgen} alt='asfsa'
                        />
                        


                    </Drawer>
                    <Drawer
                        title={language[lang].user_greement} onClose={onClose2} open={open2} placement='bottom' key='bottom' className='drawerCustom'
                    >
                        <p>
                            {language[lang].text_greement}
                        </p>
                    </Drawer>

                </div> : <div className="registerTextBlock">
                    <span>{language[lang].register_pay_text}</span>
                    <br />
                    <br />
                    <span>TF3FtZgyDza1cf4fqhczhqkqWUWgxqGwA4</span>
                </div>
            )

        )
    }

}

