import { useState, useEffect } from "react"
import { Input, Tooltip, Button, List, Avatar  } from 'antd';
import axios from 'axios'
import { useTelegram } from "../../hooks/useTelegram"
import { useNavigate } from "react-router-dom";
import language from '../../language/language.js'



export default function Trade({wtype, userData}) { 
    const navigate = useNavigate();
    const lang = (userData?.data?.lang ? userData.data.lang:'ru' )

    let balance = 0
    if (wtype == 'club'){
        balance = userData?.data?.club_balance
    } else if (wtype == 'trade'){
        balance = userData?.data?.trade_balance
    }
    const [ status, setStatus ] = useState(false)
    const {tg, user, telegram_id, first_name} = useTelegram()
    const [ data, setData ] = useState({
        id_telegram: telegram_id,
        withdrawalType: wtype,
        hash: '',
        summ: 10,
    })
    const [ history, setHistory ] = useState([])
    const [ activeApp, setActiveApp ] = useState(true)

    useEffect(() => {
        var BackButton = tg.BackButton;
        BackButton.show();
        BackButton.onClick(function() {
            navigate(-1);
            BackButton.hide();

        });

    },[])

    useEffect(() => {
        if(telegram_id){
            async function fetchData() {
                axios.get(`https://smartclub.site/api/finances/${telegram_id}?withdrawalType=${wtype}`).then((response) => {
                    if(response.status){
                        setActiveApp(response?.data?.stat)
                        setHistory(response?.data?.result)
                    }
                  });
            }
            fetchData ()
        }
    },[])

    const onAddFinances = async (obj) => {
        if (balance >= obj.summ){
            await axios.post('https://smartclub.site/api/finances', obj).then(function (response) {
                if(response.status){
                    setHistory((prev) => ({ ...prev, summ:  obj.summ}))
                    setActiveApp(true)
                }
              });
        }else{ 
            console.log('Ошибка баланс меньше нужного')
            //error
        }
        }

    const Reject = async (id_telegram, summ, withdrawalType) => {
        await axios.post('https://smartclub.site/api/finances/action', {
            id_telegram: id_telegram,
            summ: summ,
            action: 'delete',
            withdrawalType: withdrawalType
        })
        .then(function (response) {
            if (response.data.stat) {
                setActiveApp(false)
            }
        })
        }

    const onchangeDataSumm = (e) => {
        if ( e.target.value > 9 && e.target.value <= balance && e.target.value != '') {
            setData((prev) => ({ ...prev, summ: e.target.value }))
            setStatus(false)
        } else {
            setData((prev) => ({ ...prev, summ: e.target.value }))
            setStatus(true)
        }
    }
        return (
            <div className="MailList">
                 <div className="form">
                    <Form 
                        status={status} 
                        data={data} 
                        balance={balance} 
                        onchangeDataSumm={onchangeDataSumm} 
                        onAddFinances={onAddFinances}
                        activeApp={activeApp}
                        lang={lang}
                        />
                    {activeApp &&
                            <div className="finance_block">
                                <div>
                                {language[lang].sum}: {history.success ? ' + ' + history.summ + ' $' : ' - ' + history.summ + ' $'}
                                </div>
                                <div>
                                    <Button className="btn" onClick={() => Reject(history.id_telegram, history.summ, wtype)}>{language[lang].cancel}</Button >
                                </div>
                            </div>
                        
                    }
                   
                 </div>
                 
     
            </div>
         )
    
}

function Form({status, data, balance, onchangeDataSumm, onAddFinances, activeApp, lang }) {
    if(activeApp) {
        return (<> 
        <div className="header__logo">
            <h3>{language[lang].qery_subscribe}</h3>
            <br />
            <br />
        </div></>)
    }else{
        return(
            <>
             <div className="header__logo">
                <h3>{language[lang].qery_title}</h3>
            </div>
            <div>
                <br />
                <br />
                <span>{language[lang].min_sum} 10$</span><br />
                <span>{language[lang].max_sum} {balance}$</span>
            </div>
            <br />
            <br />
            <div>
                <Input status={status ? 'error' : ''} prefix={language[lang].sum} value={data.summ} onChange={onchangeDataSumm}/>
            </div>
            <br />
            <br />
            <div className="btnBlock">
                <Button disabled={status} className="btn" onClick={() => onAddFinances(data)}>{language[lang].submit}</Button >
            </div>
            <br />
            <br />
            <span>Важно! Комиссия на перевод средств с бота составляет от 3 USDT до 7 USDT, учитывайте это при выводе.</span>

            </>
        )
    }
}